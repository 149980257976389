<template>
  <b-col md="12">
    <div class="text-center py-2">
      <h2 small v-if="loading" class="mb-2">Carregando...</h2>
      <b-spinner medium v-if="loading" />
    </div>
  </b-col>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarDataCurto, formatarParaNumber, formatarValor, PascalCaseComEspaco } from '@/libs/utils'
  import { formatarDataExtensa } from '@/utils/Functions/Formatacao/Formatacao'
  import { encryptNumber } from '@/utils/encryption'
  import { agendaIcon, blankIcon, keyIcon } from '@/utils/image-module/check-icon'
  import { generateImage } from '@/utils/image-module/image-module.helper.js'
  import JSZipUtils from 'jszip-utils'
  import * as Math from 'mathjs'
  import moment from 'moment'
  import PizZip from 'pizzip'
  import Ripple from 'vue-ripple-directive'
  import saveAs from 'file-saver'
  export default {
    components: {
      formatarData,
      formatarValor,
      formatarDataCurto,
      formatarDataExtensa,
      Math,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      assinaturas: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        bancarioitems: [],
        primeiraParcela: [],
        ultimaParcela: [],
        loading: false,
        valorContrato: null,
        sexoOpcoes: {
          F: 'Feminino',
          M: 'Masculino',
          Feminino: 'Feminino',
          Masculino: 'Masculino',
          feminino: 'Feminino',
          Masculino: 'Masculino',
        },
      }
    },
    async mounted() {
      if (this.parametro) {
        this.loading = true
        await this.carregarContrato(this.parametro.contratoId)
      }
    },
    methods: {
      carregarContrato(item) {
        useJwt
          .get(`financeiro/${item}`)
          .then(async (response) => {
            let valorTotalContrato = parseFloat('0.00')
            this.contrato = response.data
            if (this.contrato.parcela.length == 0) {
              this.$emit(
                'contratoEvent',
                'error',
                'Não foi possível gerar o contrato, pois não há parcelas cadastradas para este contrato.',
              )
            }
            this.contrato.parcela.forEach((item) => {
              if (item.parcela == this.contrato.numeroParcelas) {
                this.ultimaParcela = item.vencimento
              } else if (item.parcela == '1') {
                this.primeiraParcela = item.vencimento
              }

              valorTotalContrato = valorTotalContrato + formatarParaNumber(item.valor)
            })
            this.valorContrato = valorTotalContrato.toFixed(2)

            const bancoId = this.contrato.proposta.segurado.tomador.bancoId
            await this.carregarOrgao()
            if (bancoId) this.carregarBanco(bancoId)
            else this.$message.error('O tomador não possui dados bancários cadastrados corretamente! Favor verificar')
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      carregarBanco(item) {
        useJwt
          .get(`utils/GetBancos/${item}`)
          .then((response) => {
            this.bancarioitems = response.data
            this.renderDoc(this.parametro)
          })
          .catch((error) => {
            this.loading = false
            console.error(error)
          })
      },
      formatDate(dateString) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
          timeStyle: 'long',
          timeZone: 'America/Sao_Paulo',
        }).format(date)
      },
      carregarOrgao() {
        useJwt
          .get(`cadastro/orgao/${this.contrato.proposta.segurado.orgaoId}`)
          .then((response) => {
            this.orgaoSegurado = response.data.nome
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async renderDoc(parametro) {
        try {
          this.loading = true
          const encryptedNumber = encryptNumber(parametro.contratoId)
          const fields = await this.mapFields(parametro.contratoId, encryptedNumber)

          const out = await this.generateDocument(parametro.s3Key, fields, encryptedNumber)
          await this.uploadAndDownloadDocument(out, parametro)

          this.$emit('contratoEvent', out)
        } catch (error) {
          console.error('Erro ao renderizar o documento:', error)
        } finally {
          this.loading = false
        }
      },

      async mapFields(contratoId, encryptedNumber) {
        try {
          const parametro = {
            contratoId: contratoId,
            origem: 'gerarContrato',
          }
          const { data } = await useJwt.post('tag/getData', parametro)

          if (!data) {
            console
            this.$emit['closeModal']
            return
          }

          return {
            ...data,

            // Outros dados, já carregados no front
            O004: encryptedNumber,
            O005: `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
            O006: agendaIcon,
          }
        } catch (error) {
          this.$message.error('Erro ao buscar dados do contrato')
        }
      },

      async generateDocument(s3Key, fields, encryptedNumber) {
        try {
          return await generateImage(
            s3Key,
            fields,
            `${window.location.origin}/financeiro/verifica-assinatura/${encryptedNumber}`,
          )
        } catch (error) {
          this.errorHandler(error)
          throw error
        }
      },

      async uploadAndDownloadDocument(out, parametro) {
        const formData = new FormData()
        formData.append('userId', parametro.userId)
        formData.append('financeiroId', parametro.contratoId)
        formData.append('file', out, parametro.nomeDocumento)
        formData.append('documentoId', parametro.idDocumento)

        const response = await useJwt.postDocumento('financeiro/documentacaoProcessoPdf', () => {}, formData)
        const fileData = response.data

        const downloadResponse = await useJwt.post('financeiro/getDownloadDocumentacaoProcesso', { s3Key: fileData.s3Key })
        this.downloadFile(downloadResponse.data)
      },

      downloadFile(data) {
        const buffer = new Uint8Array(data.Body.data)
        const blob = new Blob([buffer], { type: data.ContentType })
        saveAs(blob, 'contrato.pdf')
        const url = window.URL.createObjectURL(blob)
        window.open(url, '_blank')
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      },

      errorHandler(error) {
        if (error.properties?.errors instanceof Array) {
          const messages = error.properties.errors.map((err) => err.properties.explanation).join('\n')
          console.error(messages)
        }
      },
    },
  }
</script>

<style></style>
