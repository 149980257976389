<template>
  <div>
    <h1 class="p-2">Lista de Pendências</h1>
    <b-row>
      <b-col md="12">
        <b-table class="px-3" bordered outlined :items="items" :fields="fields" show-empty :busy="isBusy">
          <template #table-colgroup>
            <col style="width: 15%" />
            <col style="width: 85%" />
          </template>

          <template #empty>
            <div class="text-center">Nenhum registro encontrado</div>
          </template>

          <template #cell(concluido)="data">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                v-model="data.item.concluido"
                @change="atualizaConclusaoPendencia(data.item)"
                :disabled="!verificaPermissaoParaEditar()"
              />
            </div>
          </template>

          <template #cell(nomeDaPendencia)="data">
            <span :style="data.item.concluido ? 'text-decoration: line-through; font-weight: bold;' : null">
              {{ data.item.nomeDaPendencia }}
            </span>

            <span v-if="data.item.obrigatorio" class="ml-1">
              <b-badge variant="danger" v-b-tooltip.hover.bottom="'Obrigatório para concluir o contrato'">
                Obrigatório
              </b-badge>
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { message } from '@/main'
  import { ref } from '@vue/composition-api'
  import { jwtDecode } from 'jwt-decode'

  export default {
    name: 'PendenciasNoContrato',
    props: {
      contrato: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const items = ref([])
      const isBusy = ref(false)
      const fields = [
        {
          key: 'concluido',
          label: 'Concluir',
          thClass: 'text-center',
          sortable: false,
        },
        { key: 'nomeDaPendencia', label: 'Pendência', thClass: 'text-center', sortable: true },
      ]

      // Permissions
      const userData = jwtDecode(localStorage.getItem('userData')).userData
      let acesso = {
        ConfiguracoesSistemaPendenciasNoContratoEditar: true,
      }
      if (userData.role != 'master') {
        acesso.ConfiguracoesSistemaPendenciasNoContratoEditar =
          userData.GrupoAcesso.ConfiguracoesSistemaPendenciasNoContratoEditar
      }

      // Methods
      const carregarGrid = async () => {
        try {
          const parametro = { contratoId: props.contrato.id }
          const { data } = await useJwt.post('lista-pendencias/getListaPendenciasNoContrato', parametro)
          items.value = data
        } catch (error) {
          console.error(error)
          message.error('Erro ao carregar pendências')
        }
      }

      const atualizaConclusaoPendencia = async (item) => {
        // Atualiza a conclusão da pendência
        isBusy.value = true
        try {
          const parametro = { id: item.id, concluido: item.concluido }
          await useJwt.patch('lista-pendencias/atualizaConclusaoPendencia', parametro)
          carregarGrid()
        } catch (error) {
          console.error(error)
          message.error('Erro ao carregar pendências')
        } finally {
          isBusy.value = false
        }
      }

      const verificaPermissaoParaEditar = () => {
        if (!acesso.ConfiguracoesSistemaPendenciasNoContratoEditar) {
          return false
        }

        if (isBusy.value) {
          return false
        }

        return true
      }

      carregarGrid()

      return {
        acesso,
        items,
        fields,
        isBusy,

        carregarGrid,
        atualizaConclusaoPendencia,
        verificaPermissaoParaEditar,
      }
    },
  }
</script>
