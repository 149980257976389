<template>
  <div>
    <b-row v-if="!isBusy && totalRows > 0" class="justify-content-center align-items-center">
      <b-col md="12" class="justify-content-center d-flex">
        <b-badge class="alert-primary px-2 py-1 rounded-pill my-2 text-center text-wrap"
          >O arquivo do contrato que será enviado para o tomador assinar:</b-badge
        >
      </b-col>
      <b-col md="12">
        <b-table
          ref="tableAssinatura"
          responsive
          class="position-relative"
          :items="filelist"
          :fields="fieldsDocumento"
          :per-page="pesquisar.perPage"
          :current-page="pesquisar.currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="isBusy"
        >
          <template #cell(selecao)="row">
            <b-form-checkbox
              v-model="row.item.isSelected"
              :disabled="!row.item.isSelected && isAnySelected"
              @change="handleSelection(row.item)"
            />
          </template>
          <template #cell(originalname)="row">
            <b-link
              :disabled="!acesso.FinanceiroAnexarArquivos"
              href="#"
              @click="downloadArquivo(row.item)"
              :title="!acesso.FinanceiroAnexarArquivos ? 'Não possui permissão para abrir o documento' : 'Abrir documento'"
            >
              {{ row.item.filename }}
            </b-link>
          </template>
        </b-table>
      </b-col>
      <b-col md="12" class="d-flex justify-content-center my-1">
        <!-- Botão para enviar assinatura -->
        <b-button class="py-1 px-2 rounded-pill" variant="primary" :disabled="!selectedItem" @click="enviarAssinatura">
          <FeatherIcon icon="SendIcon" />
          Enviar
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else-if="isBusy">
      <b-col md="12" class="text-center">
        <b-skeleton-table :rows="1" :columns="3"></b-skeleton-table>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="12" class="text-center">
        <b-icon icon="file-earmark-x" variant="danger" font-scale="4" class="mb-1" />
        <h3>Nenhum documento encontrado</h3>
        <p>Não foi identificado nenhum documento no processo virtual para a assinatura</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import useJwt from '@/auth/jwt/useJwt'
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

  export default {
    name: 'EnviarDocumentoParaAssinatura',
    props: {
      parametro: {
        type: Object,
      },
    },
    components: {
      useJwt,
      downloadFileAWS,
    },
    data() {
      return {
        isBusy: false,
        filelist: [],
        totalRows: 0,
        sortBy: 'filename',
        sortDesc: false,
        sortDirection: 'asc',
        pesquisar: {
          perPage: 3,
          currentPage: 1,
        },
        fieldsDocumento: [
          { key: 'originalname', label: 'Documento' },
          {
            key: 'origemDocumento',
            label: 'Origem',
            formatter: (value) => (value ? value : 'Administração'),
          },
        ],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        documentosApp: [],
        acesso: this.$store.state.app.acesso,
        acesso: {
          FinanceiroAnexarArquivos: null,
          FinanceiroExcluirArquivos: null,
        },
        isAnySelected: false, // Indica se há alguma linha selecionada
        selectedItem: null, // Item selecionado para enviar assinatura
      }
    },
    async mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroAnexarArquivos = true
        this.acesso.FinanceiroExcluirArquivos = true
      } else {
        this.acesso.FinanceiroAnexarArquivos = this.userData.GrupoAcesso.FinanceiroAnexarArquivos
        this.acesso.FinanceiroExcluirArquivos = this.userData.GrupoAcesso.FinanceiroExcluirArquivos
      }
      await this.carregarProcessoVirtual()
    },
    methods: {
      async carregarProcessoVirtual() {
        this.isBusy = true
        try {
          const response = await useJwt.get(`financeiro/carregarProcessoVirtual/${this.parametro.id}`)
          this.isBusy = false

          if (response.data.length === 0) {
            this.$message.info('Nenhum documento encontrado para assinatura!')
            return
          }

          this.filelist = [response.data[response.data.length - 1]]
          this.filelist[0].isSelected = true
          this.isAnySelected = true
          this.selectedItem = this.filelist[0]
          this.totalRows = response.data.length

          const processoVirtual = []
          const checkList = []

          if (this.parametro.origem === 'Aplicativo') {
            this.documentosApp = await this.carregaDocumentosApp()

            this.documentosApp = this.documentosApp
              .filter((documento) => documento && documento.nome && documento.registroReconhecimentoFacialId) // Verificações adicionais
              .map((documento) => ({
                ...documento,
                filename: documento.nome,
                documentoId: documento.registroReconhecimentoFacialId,
              }))

            this.filelist = [...this.filelist, ...this.documentosApp]
            this.totalRows = this.filelist.length
          }

          this.filelist.forEach((f) => {
            if (f.documentoId !== null) {
              processoVirtual.push(f.documentoId)
            }
          })

          this.disabledDocIds = this.filelist
            .flatMap((file) => {
              if (file.documento && file.documento.documentoCheckList) {
                return file.documento.documentoCheckList
                  .filter((checklist) => checklist.documentoId !== null)
                  .map((checklist) => ({ [checklist.documentoId]: true }))
              }
              return []
            })
            .reduce((prev, cur) => ({ ...prev, ...cur }), {})

          this.$emit('atualizarManusearDocumentosEvent', {
            files: this.filelist,
            checklist: this.itemsDocumentoCheckList,
          })
          this.$emit('protocoloOkEvent', checkList.filter((x) => !processoVirtual.includes(x)).length === 0)
        } catch (error) {
          this.isBusy = false
          console.error(error)
        }
      },
      enviarAssinatura() {
        if (!this.selectedItem) {
          this.$message.erro('Selecione um arquivo para enviar assinatura!')
          return
        }

        const dados = {
          tomadorId: this.parametro.proposta.segurado.tomador.id,
          financeiroId: this.parametro.id,
          institutoId: this.userData.institutoSelecionado,
          s3KeyDocumentoPDF: this.selectedItem.s3Key,
        }

        useJwt
          .post('assinatura/enviarAssinaturaSMS', dados)
          .then((response) => {
            console.log('response', response.data)
            this.$message.success('Contrato enviado por SMS com sucesso!')
            this.$emit('fecharModalAssinaturaSMS')
          })
          .catch((error) => {
            console.log('error', error)
            this.$message.error('Erro ao enviar SMS!')
          })
      },

      handleSelection(item) {
        // Atualiza o estado de seleção
        this.filelist.forEach((row) => {
          if (row !== item) {
            row.isSelected = false
          }
        })
        this.isAnySelected = this.filelist.some((row) => row.isSelected)
        this.selectedItem = this.filelist.find((row) => row.isSelected) || null
      },

      async downloadArquivo(item) {
        const fileName = item.originalname
        this.isBusy = true
        await downloadFileAWS(item.s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>
